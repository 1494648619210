<script setup lang="ts">
import { type AnimationControls, inView, timeline, type TimelineDefinition } from 'motion'

const { play = true } = defineProps<{
  play: boolean
}>()

const triangle: Ref<null | HTMLElement> = ref(null)
const triangleDimensions = ref({ width: 0, height: 0, top: 0, left: 0 })

const circle: Ref<null | HTMLElement> = ref(null)
const circleDimensions = ref({ width: 0, height: 0, top: 0, left: 0 })

const square: Ref<null | HTMLElement> = ref(null)
const squareDimensions = ref({ width: 0, height: 0, top: 0, left: 0 })

const timelineAnimation: Ref<null | AnimationControls> = ref(null)

function calculatePositions() {
  if (!triangle.value || !circle.value || !square.value)
    return
  triangleDimensions.value = {
    width: triangle.value.offsetWidth,
    height: triangle.value.offsetHeight,
    top: triangle.value.offsetTop,
    left: triangle.value.offsetLeft,
  }

  circleDimensions.value = {
    width: circle.value.offsetWidth,
    height: circle.value.offsetHeight,
    top: circle.value.offsetTop,
    left: circle.value.offsetLeft,
  }

  squareDimensions.value = {
    width: square.value.offsetWidth,
    height: square.value.offsetHeight,
    top: square.value.offsetTop,
    left: square.value.offsetLeft,
  }
}

function animate() {
  if (!triangle.value || !circle.value || !square.value)
    return

  // Three card monty animation
  const sequence: TimelineDefinition = [
    'shuffle1',
    [triangle.value, { x: circleDimensions.value.left - triangleDimensions.value.left }, { duration: 1, at: 'shuffle1', delay: 2 }],
    [circle.value, { x: squareDimensions.value.left - circleDimensions.value.left }, { duration: 1, at: 'shuffle1', delay: 2 }],
    [square.value, { x: triangleDimensions.value.left - squareDimensions.value.left }, { duration: 1, at: 'shuffle1', delay: 2 }],
    'shuffle2',
    [triangle.value, { x: squareDimensions.value.left - triangleDimensions.value.left }, { duration: 1, at: 'shuffle2', delay: 2 }],
    [circle.value, { x: triangleDimensions.value.left - circleDimensions.value.left }, { duration: 1, at: 'shuffle2', delay: 2 }],
    [square.value, { x: circleDimensions.value.left - squareDimensions.value.left }, { duration: 1, at: 'shuffle2', delay: 2 }],
    'shuffle3',
    [triangle.value, { x: circleDimensions.value.left - triangleDimensions.value.left }, { duration: 1, at: 'shuffle3', delay: 2 }],
    [circle.value, { x: squareDimensions.value.left - circleDimensions.value.left }, { duration: 1, at: 'shuffle3', delay: 2 }],
    [square.value, { x: triangleDimensions.value.left - squareDimensions.value.left }, { duration: 1, at: 'shuffle3', delay: 2 }],
  ]

  timelineAnimation.value = timeline(sequence, { duration: 6, repeat: Infinity, direction: 'alternate' })
}

const sliceRoot: Ref<null | HTMLElement> = ref(null)

function initAnimation() {
  animate()

  return () => {
    if (timelineAnimation.value) {
      timelineAnimation.value.pause()
      timelineAnimation.value.cancel()
    }
  }
}

onMounted(() => {
  calculatePositions()

  if (!sliceRoot.value)
    return
  inView(sliceRoot.value, () => {
    if (play) {
      initAnimation()
    }
  })

  window.addEventListener('resize', calculatePositions)

  onUnmounted(() => {
    window.removeEventListener('resize', calculatePositions)
  })

  watch(() => play, (newPlay) => {
    if (newPlay) {
      initAnimation()
    }
  })
})
</script>

<template>
  <div ref="sliceRoot" class="border border-zinc-800 bg-neutral-950 rounded-lg py-12 p-8 grid grid-cols-3 gap-4">
    <div ref="triangle" class="text-center flex flex-col items-center border border-zinc-800 rounded py-4 ">
      <!-- Triangle -->
      <Icon name="heroicons:banknotes" class="w-8 h-8 text-zinc-400" />

      <div class="mt-4 text-[10px] text-zinc-500 font-sans uppercase">
        Cash
      </div>
    </div>
    <div ref="circle" class="text-center flex flex-col items-center border border-zinc-800 rounded py-4 ">
      <!-- Circle -->
      <Icon name="heroicons:paper-airplane" class="w-8 h-8 text-zinc-400" />

      <div class="mt-4 text-[10px] text-zinc-500 font-sans uppercase">
        Plane
      </div>
    </div>
    <div ref="square" class="text-center flex flex-col items-center border border-zinc-800 rounded py-4 ">
      <!-- Square -->
      <Icon name="heroicons:cube" class="w-8 h-8 text-zinc-400" />

      <div class="mt-4 text-[10px] text-zinc-500 font-sans uppercase">
        Box
      </div>
    </div>
  </div>
</template>
